.sidebar div {
	background-color: rgb(0, 54, 120) !important;
}

.main {
	background: #e5f2ff;
	/* height: 100vh; */
}

/* .active {
	background-color: aqua;
	color: #fff;
} */

.sidebar .active.active1 div {
	background-color: #fff !important;
	color: rgb(0, 54, 120) !important;
	/* background-color: #e5f2ff !important; */
	/* border-radius: 0 !important; */
	/* margin: 0 !important; */
}

.tetsttt {
	color: #fff !important;
}
