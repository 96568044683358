.dialog-model-main div div {
	max-width: 850px !important;
}

.model-width {
	width: 800px !important;
	padding: 0px !important;
}

.label-text {
	font-size: 12px;
	font-weight: 900;
	color: #001b5a;
	/* border: 1px solid #000; */
	padding: 5px;
}

.main-text {
	font-size: 16px;
	font-weight: bolder;
	color: #000034;
	/* border: 1px solid #000; */
	/* padding: 5px; */
}

.label-text1 {
	font-size: 12px;
	font-weight: 900;
	color: #001b5a;
	border: 1px solid #000;
	padding: 5px;
	width: 168px;
}

.main-text1 {
	font-size: 16px;
	font-weight: bolder;
	color: #000034;
	border: 1px solid #000;
	padding: 5px;
}

.rupee-symbol {
	height: 52px;
	background: white;
	font-size: 38px;
	color: #7c7272;
	padding-left: 10px;
}

.table-main {
	width: 100%;
}

.status-pending {
	background: #f9950078 !important;
	padding: 7px !important;
	border-radius: 10px !important;
	font-size: 16px !important;
	/* color: black; */
	font-weight: 900 !important;
}

.status-approved {
	background: #14bd0078 !important;
	padding: 7px !important;
	border-radius: 10px !important;
	font-size: 16px !important;
	/* color: black; */
	font-weight: 900 !important;
}

.status-reject {
	background: #f7000059 !important;
	padding: 7px !important;
	border-radius: 10px !important;
	font-size: 16px !important;
	/* color: black; */
	font-weight: 900 !important;
}
