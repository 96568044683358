.dialog-model-main div div {
	max-width: 850px !important;
}

.model-width {
	width: 800px !important;
	padding: 0px !important;
}

.label-text {
	font-size: 14px;
	font-weight: 900;
	color: #001b5a;
}

.main-text {
	font-size: 20px;
	font-weight: bolder;
	color: #000034;
}
