@import url("https://fonts.googleapis.com/css?family=Nunito:600,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

.MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.MuiListItemButton-root.MuiListItemButton-gutters.css-1m5i5w0-MuiButtonBase-root-MuiListItemButton-root {
  margin: 5px;
  border-radius: 25px;
  font-weight: bolder;
  color: grey
}

.active .MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.MuiListItemButton-root.MuiListItemButton-gutters.css-1m5i5w0-MuiButtonBase-root-MuiListItemButton-root {
  background: rgba(32, 101, 209, 0.08);
  color: #2065D1;
}

.active svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: #2065D1;
}